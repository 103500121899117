'use client';
import React from "react";
import { Block } from "../base"
import { BlockContainer } from "../base/block-container";
import { FlexContainer } from "@/uikit/container";
import { Typography } from "@/uikit/Typography";
import { first, firstBlockLeftColumn, labelStyle, labelWrapper, second, secondRight, textDescription, third, thirdImg, titleColor, titleStyle } from "./howWeDoIt.css";
import { RemoteImage } from "@/uikit/remote-image";

const HowWeDoIt = Block("howWeDoIt", ({ title, firstBlock, firstBlockDescription, firstImage, secondBlock, secondBlockDescription, secondImage, thirdBlock, label, thirdBlockDescription, thirdImage }) => {
    return (
        <BlockContainer blockPaddingTop="medium" id="how-we-do-it" layout="centeredColumn">
            <FlexContainer alignItems="center" justifyContent="center">
                <Typography settings={{ tag: "h2" }}>
                    {title}
                </Typography>
            </FlexContainer>
            <FlexContainer alignItems="center" justifyContent="between" padding="paddingInline" gap="80" className={first}>
                <FlexContainer direction="column" justifyContent="center" className={firstBlockLeftColumn}>
                    <Typography settings={{ tag: "heading" }}>
                        {firstBlock && firstBlock.map((part: any, index: any) => (
                            <React.Fragment key={part._key}>
                                <span className={titleColor({ color: part.color })}>
                                    {part.text}
                                </span>
                                {index < firstBlock.length - 1 && ' '}
                            </React.Fragment>
                        ))}
                    </Typography>
                    <Typography className={textDescription({
                        fontStyle: "normal"
                    })}>
                        {firstBlockDescription}
                    </Typography>
                </FlexContainer>
                <FlexContainer direction="column" alignItems="center" justifyContent="center" >
                    {firstImage && (
                        <RemoteImage
                            image={firstImage}
                            sizes={{
                                mobile: "100%",
                                tablet: "495px",
                                desktop: "495px",
                            }}
                        />
                    )}
                </FlexContainer>
            </FlexContainer>
            <FlexContainer alignItems="center" justifyContent="between" padding="paddingInline" gap="80" className={second}>
                <FlexContainer direction="column" justifyContent="center">
                    {secondImage && (
                        <RemoteImage
                            image={secondImage}
                            sizes={{
                                mobile: "100%",
                                tablet: "495px",
                                desktop: "495px",
                            }}
                        />
                    )}
                </FlexContainer>
                <FlexContainer direction="column" alignItems="center" justifyContent="center" className={secondRight}>
                    <Typography settings={{ tag: "heading" }}>
                        {secondBlock && secondBlock.map((part: any, index: any) => (
                            <React.Fragment key={part._key}>
                                <span className={titleColor({ color: part.color })}>
                                    {part.text}
                                </span>
                                {index < secondBlock.length - 1 && ' '}
                            </React.Fragment>
                        ))}
                    </Typography>
                    {secondBlockDescription && secondBlockDescription.map((part: any, index: any) => (
                        <span key={index} className={textDescription({
                            fontStyle: part.style
                        })}>{part.text}</span>
                    ))}
                </FlexContainer>
            </FlexContainer>
            <FlexContainer alignItems="center" justifyContent="between" padding="paddingInline" gap="80" className={third}>
                <FlexContainer direction="column" alignItems="start" justifyContent="center" className={secondRight}>
                    <Typography settings={{ tag: "heading" }}>
                        {thirdBlock && thirdBlock.map((part: any, index: any) => (
                            <React.Fragment key={part._key}>
                                <span className={titleColor({ color: part.color })}>
                                    {part.text}
                                </span>
                                {index < thirdBlock.length - 1 && ' '}
                            </React.Fragment>
                        ))}
                    </Typography>
                    {thirdBlockDescription && thirdBlockDescription.map((part: any, index: any) => (
                        <span key={index} className={textDescription({
                            fontStyle: part.style
                        })}>{part.text}</span>
                    ))}
                    {label && (
                        <div className={labelWrapper}>
                            <Typography className={labelStyle} settings={{ tag: "label" }}>
                                {label}
                            </Typography>
                        </div>
                    )}
                </FlexContainer>
                <FlexContainer direction="column" justifyContent="center">
                    {thirdImage && (
                        <RemoteImage
                            image={thirdImage}
                            className={thirdImg}
                            sizes={{
                                mobile: "100%",
                                tablet: "495px",
                                desktop: "495px",
                            }}
                        />
                    )}
                </FlexContainer>
            </FlexContainer>
        </BlockContainer>
    )
})

export default HowWeDoIt;