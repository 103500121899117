import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fblocks%2FhowWeDoIt%2FhowWeDoIt.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA7VUwW6jMBS85yueVK3USuvIOCQQ97K97H8YbIhbYiPjJLSr%2FPsKY1o7NFF7qAQc3hvm%2Bc0MLI82P6pnDP8WAC3jXKoaWd1S2KRt%2F7g4L5YjIpkjchwhyByR4BiycpBSN9pQuMMYPy4AKq0s6uSboJDnA9yXTkLWO0shG2GNVALtfC3B%2BFfAu57zvvc2US95yrK%2FJGhnrr1nPTpJbncU0vU6OnN%2BCdgQEgG2DlCw8qU2%2BqA4hbuqqoYjF9pwYZBhXB46CgSP2xW6R92OcX2igNsekuGRDo%2FhNnXB7vFv8NcywQ%2FBMBZtk6bphYJkM86I1UojtQrHEWmcRpKVc0AWAbgDeD1GLwC47NqGvVKoGjG62IgecWlEaaVWFIw%2BDWXWyFohacW%2Bo1AKZYUZys%2BHzsrqFZVaWaHsSIM6y4wNJgs3eTbqq5wfLZ9TCglp%2B3drauZSO%2FkU2bfdbn09tHoK1BARU0s1Bp%2F4j%2BfPXnDJ4D6IT4LJqu0f3B7L8OuLQjaJOlex1M1hr5ARR2E6MWLcsVN%2F7PMHcfJ94tuExBN%2B8h%2F4kQVSP8%2BK3iJncuhhAMxubXojBlejE3DnP8jNvrog98Ab886fBy7bZFfy5lRfkauxifoXpt8IxzdfmyQI%2FmJJPqHO%2FwGMDeKZoQYAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var first = 'vt8vnj0';
export var firstBlockLeftColumn = 'vt8vnj7';
export var labelStyle = 'vt8vnje';
export var labelWrapper = 'vt8vnjd';
export var second = 'vt8vnj1';
export var secondRight = 'vt8vnj8';
export var textDescription = _7a468({defaultClassName:'vt8vnja',variantClassNames:{fontStyle:{normal:'vt8vnjb',bold:'vt8vnjc'}},defaultVariants:{},compoundVariants:[]});
export var third = 'vt8vnj2';
export var thirdImg = 'vt8vnj9';
export var titleColor = _7a468({defaultClassName:'vt8vnj4',variantClassNames:{color:{black:'vt8vnj5',blue:'vt8vnj6'}},defaultVariants:{},compoundVariants:[]});
export var titleStyle = 'vt8vnj3';